import {
  createHashRouter, Navigate, useLocation,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import HEADERS from '@constants/headers';
import { ACCOUNT_STATUS } from '@constants/accountStatus';

import Login from '@containers/Auth/Login';
import ChangePassword from '@containers/Auth/ChangePassword';
import MyProfilePage from '@containers/MyProfilePage';

import Error from '@containers/Static/Error';
import NotFound from '@containers/Static/NotFound';

import Users from '@containers/Users';
import UserDetails from '@containers/UserDetails';

import AdminTools from '@containers/AdminTools/AdminTools';
import Currency from '@containers/AdminTools/Currency';
import Company from '@containers/AdminTools/Company';
import Location from '@containers/AdminTools/Location';
import OsdReason from '@containers/AdminTools/OsdReason';
import ModeTransport from '@containers/AdminTools/ModeTransport';
import Tariff from '@containers/AdminTools/Tariff';
import UnitMeasure from '@containers/AdminTools/UnitMeasure';
import Warehouse from '@containers/AdminTools/Warehouse';
import PartyList from '@containers/AdminTools/Party';

import PurchaseOrder_Labs from '@containers/TH_PurchaseOrders/PurchaseOrder_Labs';
import AddPartsList from '@containers/TH_AddParts';
import WarehouseIn from '@containers/TH_WarehouseIn';
import WarehouseInList from '@containers/TH_WarehouseIn/WarehouseInList';
import WarehouseInInboundJob from '@containers/TH_WarehouseIn/WarehouseInInboundJob/WarehouseInInboundJob';
import WarehouseOut from '@containers/TH_WarehouseOut';
import WarehouseOutList from '@containers/TH_WarehouseOut/WarehouseOutList';

import Main from '@components/Main';

import Root from '../Root';

function PrivateRoute({ isAuthenticated, accountStatus, Component }) {
  const { pathname } = useLocation();

  if (isAuthenticated) {
    switch (accountStatus.Account_Status) {
      case ACCOUNT_STATUS.ACTIVE:
        return (
          <ErrorBoundary fallback={<Error />}>
            <Main {...HEADERS[pathname]}>
              <Component />
            </Main>
          </ErrorBoundary>
        );
      case ACCOUNT_STATUS.RESET:
        return <Navigate to="/change-password" />;
      default:
        return <Navigate to="/login" />;
    }
  }
  return <Navigate to="/login" />;
}

const routes = (isAuthenticated, accountStatus) => createHashRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/public/users', element: <Users /> },
      { path: '/change-password', element: isAuthenticated ? <ChangePassword /> : <Navigate to="/login" /> },
      { path: '/my-profile', element: <PrivateRoute isAuthenticated={isAuthenticated} accountStatus={accountStatus} Component={MyProfilePage} /> },
      { path: '/po-parts-labs', element: <PrivateRoute isAuthenticated={isAuthenticated} accountStatus={accountStatus} Component={PurchaseOrder_Labs} /> },
      { path: '/add-parts', element: <PrivateRoute isAuthenticated={isAuthenticated} accountStatus={accountStatus} Component={AddPartsList} /> },
      {
        path: '/warehouse-in',
        element: <PrivateRoute
          isAuthenticated={isAuthenticated}
          accountStatus={accountStatus}
          Component={WarehouseIn}
        />,
        children: [
          { path: '/warehouse-in/list', element: <WarehouseInList /> },
          { path: '/warehouse-in/inbound-job/:id', element: <WarehouseInInboundJob /> },
        ],
      },
      {
        path: '/warehouse-out',
        element: <PrivateRoute
          isAuthenticated={isAuthenticated}
          accountStatus={accountStatus}
          Component={WarehouseOut}
        />,
        children: [
          { path: '/warehouse-out/list', element: <WarehouseOutList /> },
          { path: '/warehouse-out/outbound-job/:id', element: <WarehouseOutList /> },
        ],
      },
      {
        path: '/admin',
        element: <PrivateRoute
          isAuthenticated={isAuthenticated}
          accountStatus={accountStatus}
          Component={AdminTools}
        />,
        children: [
          { path: '/admin/currency', element: <Currency /> },
          { path: '/admin/company', element: <Company /> },
          { path: '/admin/location', element: <Location /> },
          { path: '/admin/osdreason', element: <OsdReason /> },
          { path: '/admin/modetransport', element: <ModeTransport /> },
          { path: '/admin/tariff', element: <Tariff /> },
          { path: '/admin/unitmeasure', element: <UnitMeasure /> },
          { path: '/admin/warehouse', element: <Warehouse /> },
          { path: '/admin/party', element: <PartyList /> },
          { path: '/admin/users', element: <Users /> },
          { path: '/admin/users/details', element: <UserDetails /> },
        ],
      },
    ],
  },
  { path: '*', element: <NotFound /> },
]);

export default routes;
