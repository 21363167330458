import React, { memo, useCallback } from 'react';

import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ButtonRounded, ContentCentered } from '@components/Styles/Global';

function Error() {
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate('/');
  }, []);
  return (
    <ContentCentered>
      <Result
        status="500"
        title="Internal Server Error"
        subTitle="Sorry, something went wrong on our end."
        extra={<ButtonRounded block size="large" type="primary" onClick={handleBack}>Go Back</ButtonRounded>}
      />
    </ContentCentered>
  );
}

export default memo(Error);
