import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button,
  Col, Divider, Row,
  Skeleton,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import GET_OVERPACK_SCAN_IDS_BY_WAREHOUSE_OUT_AND_CUSTOMER from '@graphql/getOverpackScanIdsByWarehouseOutAndCustomer';
import UPDATE_OVERPACK_SCAN_ID from '@graphql/TH_updateOverpackScanId';
import CREATE_OVERPACK_SCAN_ID from '@graphql/TH_createOverpackScanId';
import GET_SCAN_ID_BY_ID from '@graphql/TH_getScanIdById';
import GET_UNPACKED_SCAN_IDS from '@graphql/TH_getUnpackedScanIdsByWarehouseOutId';
import GET_ATTACHED_SCAN_IDS from '@graphql/getAttachedScanIdsByOverpackScanId';
import REMOVE_FROM_OVERPACK_SCAN_ID from '@graphql/TH_removeFromOverPack';
import ADD_TO_OVERPACK_SCAN_ID from '@graphql/TH_ addToOverPack';
import CLOSE_OVERPACK from '@graphql/TH_closeOverpack';
import REOPEN_OVERPACK from '@graphql/TH_reopenOverpack';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import useList from '@hooks/useList';
import useDetail from '@hooks/useDetail';
import { timestampToDate } from '@utils/helpers/date';
import {
  convertCmToInch, convertInchToCm, convertKgToPounds, convertPoundsToKg, getVolumeCbm, getVolumeCft,
} from '@utils/helpers/computations';

import { CardStyled } from '@components/Styles/CardStyles';
import { ContentStyled } from '@components/Styles/Global';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';
import { parseAndToFixed } from '@utils/helpers/helpers';
import OverpackForm from '../Components/OverpackForm';
import OverpackLineItems from '../Components/OverpackLineItems';

function OutboundJobPack({
  currentTab,
  onSetModalActions,
  record,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowAttachedKeys, setSelectedRowAttachedKeys] = useState([]);
  const [selectedRowUnpackedKeys, setSelectedRowUnpackedKeys] = useState([]);
  const [formDataCreateOverpack, setFormDataCreateOverpack] = useState(null);
  const [formData, setFormData] = useState(null);
  const [selectedScanId, setSelectedScanId] = useState(null);
  const [formDataAddOverpack, setFormDataAddOverpack] = useState(null);
  const [formDataRemoveOverpack, setFormDataRemoveOverpack] = useState(null);
  const [formDataCloseOverpack, setFormDataCloseOverpack] = useState(null);
  const [formDataReopenOverpack, setFormDataReopenOverpack] = useState(null);
  const [onNewDataCallback, setOnNewDataCallback] = useState(null);
  const [form] = useForm();

  const [getOverpackLineItems, { data, loading, refetch }] = useLazyQuery(GET_OVERPACK_SCAN_IDS_BY_WAREHOUSE_OUT_AND_CUSTOMER, {
    variables: { WarehouseOutId: record?.Id },
  });

  const overpackLineItems = get(data, 'results', []);

  const { data: scanIdDetails, loading: overpackDetailLoading, refetch: refetchScanIdDetails } = useDetail({ query: GET_SCAN_ID_BY_ID, id: selectedScanId });

  const { loading: listPickedLoading, data: unpackedScanIdList, refetch: refetchUnpacked } = useList({
    skip: !scanIdDetails?.Id,
    query: GET_UNPACKED_SCAN_IDS,
    variables: {
      WarehouseOutId: record?.Id,
    },
  });

  const { loading: listAttachedLoading, data: attachedScanIdList, refetch: refetchAttached } = useList({
    skip: !scanIdDetails?.Id,
    query: GET_ATTACHED_SCAN_IDS,
    variables: {
      WarehouseOutId: record?.Id,
      OverpackScanId: scanIdDetails?.Id,
    },
  });

  const { loading: loadingSaveOverpack } = useCreateUpdateRecord({
    formData,
    mutation: UPDATE_OVERPACK_SCAN_ID,
    variables: Boolean(scanIdDetails?.Id) && { id: scanIdDetails?.Id },
    callback: () => handleSuccessSaveOverpack(),
  });

  const { loading: loadingCreateOverpack } = useCreateUpdateRecord({
    formData: formDataCreateOverpack,
    mutation: CREATE_OVERPACK_SCAN_ID,
    callback: () => handleSuccessCreateOverpack(),
  });

  const { loading: loadingAddOverpack } = useCreateUpdateRecord({
    formData: formDataAddOverpack,
    mutation: ADD_TO_OVERPACK_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const { loading: loadingRemoveOverpack } = useCreateUpdateRecord({
    formData: formDataRemoveOverpack,
    mutation: REMOVE_FROM_OVERPACK_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const { loading: loadingCloseOverpack } = useCreateUpdateRecord({
    formData: formDataCloseOverpack,
    mutation: CLOSE_OVERPACK,
    callback: () => handleSuccessOverpack(),
  });

  const { loading: loadingReopenOverpack } = useCreateUpdateRecord({
    formData: formDataReopenOverpack,
    mutation: REOPEN_OVERPACK,
    callback: () => handleSuccessOverpack(),
  });

  const handleSelectRowKeys = useCallback((value) => {
    setSelectedRowKeys(value);
  }, []);

  const handleAdd = useCallback(() => {
    setFormDataCreateOverpack({
      WarehouseOut_Fk: record?.Id,
    });
  }, []);

  const handleSuccessSaveOverpack = useCallback(() => {
    refetch();
  }, [record?.Id]);

  const handleSuccessCreateOverpack = useCallback(async () => {
    await refetch();

    if (onNewDataCallback) {
      onNewDataCallback();
    }
  }, [record?.Id, onNewDataCallback]);

  const handleOnNewDataCallback = useCallback((callback) => {
    setOnNewDataCallback(callback);
  }, []);

  const handleSuccess = useCallback(() => {
    setSelectedRowAttachedKeys([]);
    setSelectedRowUnpackedKeys([]);
    refetchUnpacked();
    refetchAttached();
  }, [record?.Id]);

  const handleSuccessOverpack = useCallback(() => {
    refetchScanIdDetails();
  }, [record?.Id]);

  const handleFinish = useCallback((values) => {
    const newData = {
      ...values,
      UnitOfMeasure_Fk: get(values, 'UnitOfMeasure_Fk.value', values.UnitOfMeasure_Fk || null),
    };

    delete newData.PackedDate;

    setFormData(newData);
  }, [selectedRowKeys]);

  const handleSelectOverpack = useCallback((scanId) => {
    setSelectedScanId(scanId);
  }, []);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, []);

  const handleAddOverpack = useCallback(() => {
    setFormDataAddOverpack({
      ScanId_POPartLocation_Fks: selectedRowUnpackedKeys,
      OverpackId: scanIdDetails?.Id,
    });
  }, [selectedRowUnpackedKeys, scanIdDetails?.Id]);

  const handleRemoveOverpack = useCallback(() => {
    setFormDataRemoveOverpack({
      ScanId_POPartLocation_Fks: selectedRowAttachedKeys,
      OverpackId: scanIdDetails?.Id,
    });
  }, [selectedRowAttachedKeys, scanIdDetails?.Id]);

  const handleCloseOverpack = useCallback(() => {
    setFormDataCloseOverpack({
      OverpackId: scanIdDetails?.Id,
    });
  }, [scanIdDetails?.Id]);

  const handleReopenOverpack = useCallback(() => {
    setFormDataReopenOverpack({
      OverpackId: scanIdDetails?.Id,
    });
  }, [scanIdDetails?.Id]);

  const handleValuesChange = useCallback((changedFields, allValues) => {
    const changeKey = Object.keys(changedFields)[0];
    const changeValue = changedFields[changeKey];
    let parsedValue;

    if (changeKey.includes('Inch')) {
      parsedValue = convertInchToCm(changeValue);
    } else if (changeKey.includes('Cm')) {
      parsedValue = convertCmToInch(changeValue);
    } else if (changeKey.includes('Lbs')) {
      parsedValue = convertPoundsToKg(changeValue);
    } else if (changeKey.includes('Kg')) {
      parsedValue = convertKgToPounds(changeValue);
    }

    if (changeKey === 'LengthInch') {
      form.setFieldValue('LengthCm', parsedValue);
    } else if (changeKey === 'WidthInch') {
      form.setFieldValue('WidthCm', parsedValue);
    } else if (changeKey === 'HeightInch') {
      form.setFieldValue('HeightCm', parsedValue);
    } else if (changeKey === 'LengthCm') {
      form.setFieldValue('LengthInch', parsedValue);
    } else if (changeKey === 'WidthCm') {
      form.setFieldValue('WidthInch', parsedValue);
    } else if (changeKey === 'HeightCm') {
      form.setFieldValue('HeightInch', parsedValue);
    } else if (changeKey === 'WeightKg') {
      form.setFieldValue('WeightLbs', parsedValue);
    } else if (changeKey === 'WeightLbs') {
      form.setFieldValue('WeightKg', parsedValue);
    }

    if (['LengthInch', 'WidthInch', 'HeightInch', 'LengthCm', 'WidthCm', 'HeightCm'].includes(changeKey)) {
      form.setFieldValue('VolumeCft', getVolumeCft(allValues.LengthInch, allValues.WidthInch, allValues.HeightInch));
      form.setFieldValue('VolumeCbm', getVolumeCbm(allValues.LengthCm, allValues.WidthCm, allValues.HeightCm));
    }
  }, []);

  useEffect(() => {
    if (record?.Id) {
      getOverpackLineItems();
    }
  }, [record?.Id]);

  useEffect(() => {
    if (currentTab === 'pack') {
      const isDisabledAllButton = selectedRowAttachedKeys.length === 0 && selectedRowUnpackedKeys.length === 0;
      onSetModalActions([
        <Space key={1}>&nbsp;</Space>,
        scanIdDetails?.Id && (
        <Space key={2}>
          <Button type="default" onClick={handleAddOverpack} disabled={loadingAddOverpack || selectedRowAttachedKeys.length > 0 || isDisabledAllButton} loading={loadingAddOverpack}>
            Add to Overpack
          </Button>
          <Button type="default" onClick={handleRemoveOverpack} disabled={loadingRemoveOverpack || selectedRowUnpackedKeys.length > 0 || isDisabledAllButton} loading={loadingRemoveOverpack}>
            Remove from Overpack
          </Button>
          <Button type="default" onClick={handleReopenOverpack} disabled={loadingReopenOverpack || !scanIdDetails?.PackedDate} loading={loadingReopenOverpack}>
            Re-open Overpack
          </Button>
          <Button type="default" onClick={handleCloseOverpack} disabled={loadingCloseOverpack || scanIdDetails?.PackedDate} loading={loadingCloseOverpack}>
            Close Overpack
          </Button>
          <div style={{ width: 24 }}>&nbsp;</div>
          <Button type="primary" onClick={handleSubmit} disabled={loadingSaveOverpack} loading={loadingSaveOverpack}>
            Save
          </Button>
        </Space>
        ),
      ]);
    }
  }, [currentTab, scanIdDetails?.Id, selectedRowAttachedKeys, selectedRowUnpackedKeys, loadingSaveOverpack,
    loadingAddOverpack, loadingRemoveOverpack, scanIdDetails?.PackedDate, loadingCloseOverpack, loadingReopenOverpack]);

  const scanIdColumns = [
    {
      title: 'Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Warehouse',
      dataIndex: 'Warehouse',
      key: 'Warehouse',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
    {
      title: 'Storage Location',
      dataIndex: 'StorageLocation',
      key: 'StorageLocation',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code}
        </span>
      ),
    },
    {
      title: 'Dimensions (INCH) L x W x H',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthInch) || 0} x ${parseAndToFixed(record?.WidthInch) || 0} x ${parseAndToFixed(record?.HeightInch) || 0}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (CM) L x W x H',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthCm) || 0} x ${parseAndToFixed(record?.WidthCm) || 0} x ${parseAndToFixed(record?.HeightCm) || 0}`}
        </span>
      ),
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Volume (CFT)',
      dataIndex: 'VolumeCft',
      key: 'VolumeCft',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
  ];

  const lineItemColumns = [
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const pickedScanIdColumns = [...scanIdColumns];

  pickedScanIdColumns.splice(1, 0, {
    title: 'Picked Date',
    dataIndex: 'PickedDate',
    key: 'PickedDate',
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {timestampToDate(text, true)}
      </span>
    ),
  });

  pickedScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${unpackedScanIdList.length}`}
      </span>
    ),
  });

  const attachedScanIdColumns = [...scanIdColumns];

  attachedScanIdColumns.splice(1, 0, {
    title: 'Packed Date',
    dataIndex: 'PackedDate',
    key: 'PackedDate',
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {timestampToDate(text, true)}
      </span>
    ),
  });

  attachedScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${attachedScanIdList.length}`}
      </span>
    ),
  });

  const rowSelectionAttached = {
    onChange: (selectedRowKeys) => {
      setSelectedRowUnpackedKeys([]);
      setSelectedRowAttachedKeys(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: (AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0) || scanIdDetails?.PackedDate,
    }),
  };

  const rowSelectionUnpacked = {
    onChange: (selectedRowKeys) => {
      setSelectedRowUnpackedKeys(selectedRowKeys);
      setSelectedRowAttachedKeys([]);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: (AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0) || scanIdDetails?.PackedDate,
    }),
  };

  const expandedRowRender = ({ ScanId, AttachedPOLineItemToScanId }) => (
    <>
      {AttachedPOLineItemToScanId.length > 0 ? (
        <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
          <TableSubSmall
            size="small"
            dataSource={AttachedPOLineItemToScanId}
            columns={lineItemColumns}
            pagination={false}
            rowKey={(record) => `${ScanId}-${record?.Id}`}
            scroll={{
              x: true,
            }}
          />
        </div>
      ) : (<div>No Items</div>)}
    </>
  );

  return (
    <ContentStyled style={{ margin: 12 }}>
      <Row gutter={[12]}>
        <Col sm={6}>
          <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
            <OverpackLineItems
              onAddClick={handleAdd}
              onSelect={handleSelectOverpack}
              onSelectRowKeys={handleSelectRowKeys}
              data={overpackLineItems}
              loading={loading}
              loadingAdd={loadingCreateOverpack}
              selectedRowKeys={selectedRowKeys}
              onNewDataCallback={handleOnNewDataCallback}
            />
          </CardStyled>
        </Col>
        <Col sm={18}>
          <Skeleton loading={overpackDetailLoading} active>
            <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
              <Spin spinning={overpackDetailLoading}>
                <OverpackForm
                  form={form}
                  onFinish={handleFinish}
                  scanIdDetails={scanIdDetails}
                  onValuesChange={handleValuesChange}
                />
              </Spin>
            </CardStyled>
            <CardStyled>
              <Typography.Title level={5}>Packed Scan IDs</Typography.Title>
              <TableSmall
                style={{ marginTop: 24 }}
                size="small"
                dataSource={attachedScanIdList}
                loading={listAttachedLoading}
                columns={attachedScanIdColumns}
                pagination={false}
                scroll={{
                  x: true,
                }}
                rowKey="Id"
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRowAttachedKeys,
                  ...rowSelectionAttached,
                }}
                expandable={{
                  rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                  defaultExpandAllRows: true,
                  expandedRowRender,
                }}
              />
            </CardStyled>
            <Divider />
            <CardStyled>
              <Typography.Title level={5}>Picked / Unpacked Scan IDs</Typography.Title>
              <TableSmall
                style={{ marginTop: 24 }}
                size="small"
                dataSource={unpackedScanIdList}
                loading={listPickedLoading}
                columns={pickedScanIdColumns}
                pagination={false}
                scroll={{
                  x: true,
                }}
                rowKey="Id"
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRowUnpackedKeys,
                  ...rowSelectionUnpacked,
                }}
                expandable={{
                  rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                  defaultExpandAllRows: true,
                  expandedRowRender,
                }}
              />
            </CardStyled>
          </Skeleton>
        </Col>
      </Row>

    </ContentStyled>
  );
}

export default memo(OutboundJobPack);
