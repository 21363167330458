import React, {
  memo, useCallback, useState,
} from 'react';
import { Tabs } from 'antd';

import GET_OUTBOUND_JOB_BY_ID from '@graphql/TH_getOutboundJobById';

import useDetail from '@hooks/useDetail';

import SectionLoading from '@components/SectionLoading';
import OutboundJob from './Tabs/OutboundJob';
import OutboundJobPrint from './Tabs/OutboundJobPrint';
import OutboundJobAuthorizedPick from './Tabs/OutboundJobAuthorizedPick';
import OutboundJobDispatch from './Tabs/OutboundJobDispatch';
import OutboundJobPick from './Tabs/OutboundJobPick';
import OutboundJobPack from './Tabs/OutboundJobPack';
import OutboundJobLoadPlan from './Tabs/OutboundJobLoadPlan';

function WarehouseInInboundJob({
  recordId,
  onSetActions,
  onCancel,
  onSuccess,
}) {
  const [currentTab, setCurrentTab] = useState('outboundJobs');

  const { data: detail, loading: detailLoading } = useDetail({ query: GET_OUTBOUND_JOB_BY_ID, id: recordId });

  const handleChangeTab = useCallback((key) => {
    setCurrentTab(key);
  }, []);

  const handleSetModalActions = useCallback((modalActions) => {
    onSetActions(modalActions);
  }, []);

  const items = [
    {
      key: 'outboundJobs',
      label: 'Outbound Job',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJob
            record={detail}
            onSuccess={onSuccess}
            onCancel={onCancel}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'authorizePick',
      label: 'Authorize to Pick',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJobAuthorizedPick
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
            onSuccess={onSuccess}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'pick',
      label: 'Pick',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJobPick
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
            onSuccess={onSuccess}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'pack',
      label: 'Pack',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJobPack
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
            onSuccess={onSuccess}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'loadPlan',
      label: 'Load Plan',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJobLoadPlan
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
            onSuccess={onSuccess}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'dispatch',
      label: 'Dispatch',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJobDispatch
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
            onSuccess={onSuccess}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'printDocument',
      label: 'Print Document',
      children: (
        <SectionLoading loading={detailLoading}>
          <OutboundJobPrint
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
          />
        </SectionLoading>
      ),
    },
  ].filter(Boolean);

  return (
    <Tabs defaultActiveKey="outbound-jobs" items={items} onChange={handleChangeTab} />
  );
}

export default memo(WarehouseInInboundJob);
