import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Col, Divider, Flex, Popconfirm, Row, Space, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/es/form/Form';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

import GET_SCAN_ID_BY_JOB_NUMBER from '@graphql/TH_getScanIdByJobNumber';
import INBOUND_JOB_CONFIRM_GOODS from '@graphql/TH_inboundJobConfirmGoods';
import INBOUND_JOB_UNCONFIRM_GOODS from '@graphql/TH_inboundJobUnConfirmGoods';
import INBOUND_JOB_PUTAWAY_GOODS from '@graphql/TH_inboundJobPutawayGoods';
import INBOUND_JOB_UNCONFIRM_PUTAWAY_GOODS from '@graphql/TH_inboundJobUnConfirmPutawayGoods';
import UPDATE_SCAN_ID from '@graphql/TH_updateScanId';
import CREATE_SCAN_ID from '@graphql/TH_createScanId';
import GET_SCAN_ID_BY_ID from '@graphql/TH_getScanIdById';
import CANCEL_SCAN_ID from '@graphql/cancelScanId';

import useList from '@hooks/useList';
import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import useDetail from '@hooks/useDetail';

import { CardStyled } from '@components/Styles/CardStyles';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';

import {
  convertCmToInch, convertInchToCm, convertKgToPounds, convertPoundsToKg,
  getVolumeCbm,
  getVolumeCft,
} from '@utils/helpers/computations';
import { parseScanIdStatus } from '@utils/helpers/status';
import { get } from 'lodash';
import ManualDistribution from '../Modals/ManualDistribution';
import AutoDistribution from '../Modals/AutoDistribution';
import InboundJobAddScanId from '../Modals/InboundJobAddScanId';

function InboundJobReceivedGoods({
  record,
  currentTab,
  poLineItems,
  poLineItemsLoading,
  poLineItemColumns,
  onSetModalActions,
  onSuccessDistributeQuantity,
  onSuccessConfirmUnconfirmGoods,
}) {
  const [formData, setFormData] = useState(null);
  const [formDataConfirmGoods, setFormDataConfirmGoods] = useState(null);
  const [formDataUnConfirmGoods, setFormDataUnConfirmGoods] = useState(null);
  const [formDataPutawayGoods, setFormDataPutawayGoods] = useState(null);
  const [formDataUnConfirmPutawayGoods, setFormDataUnConfirmPutawayGoods] = useState(null);
  const [formDataDelete, setFormDataDelete] = useState(null);
  const [selectedScanId, setSelectedScanId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [scanIdForm] = useForm();

  const { loading: listLoading, data: list, refetch } = useList({
    query: GET_SCAN_ID_BY_JOB_NUMBER,
    variables: {
      JobNumber: record?.JobNumber,
    },
  });

  const { data: scanIdDetail, loading: detailLoading } = useDetail({ query: GET_SCAN_ID_BY_ID, id: selectedScanId });

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: scanIdDetail?.Id ? UPDATE_SCAN_ID : CREATE_SCAN_ID,
    variables: Boolean(scanIdDetail?.Id) && { id: scanIdDetail?.Id },
    callback: () => handleSuccess(),
  });

  const { loading: loadingConfirmGoods } = useCreateUpdateRecord({
    formData: formDataConfirmGoods,
    mutation: INBOUND_JOB_CONFIRM_GOODS,
    callback: () => handleSuccesConfirmUnconfirmGoods(),
  });

  const { loading: loadingUnConfirmGoods } = useCreateUpdateRecord({
    formData: formDataUnConfirmGoods,
    mutation: INBOUND_JOB_UNCONFIRM_GOODS,
    callback: () => handleSuccesConfirmUnconfirmGoods(),
  });

  const { loading: loadingPutawayGoods } = useCreateUpdateRecord({
    formData: formDataPutawayGoods,
    mutation: INBOUND_JOB_PUTAWAY_GOODS,
    callback: () => handleSuccess(),
  });

  const { loading: loadingUnconfirmPutawayGoods } = useCreateUpdateRecord({
    formData: formDataUnConfirmPutawayGoods,
    mutation: INBOUND_JOB_UNCONFIRM_PUTAWAY_GOODS,
    callback: () => handleSuccess(),
  });

  useCreateUpdateRecord({
    formData: formDataDelete,
    variables: { id: formDataDelete?.id },
    mutation: CANCEL_SCAN_ID,
    isDelete: true,
    callback: () => handleSuccessDelete(),
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setSelectedScanId(null);
    setOpen(false);
  }, []);

  const handleEdit = useCallback((scanId) => {
    setSelectedScanId(scanId);
    setOpen(true);
  }, []);

  const handleValuesChange = useCallback((changedFields, allValues) => {
    const changeKey = Object.keys(changedFields)[0];
    const changeValue = changedFields[changeKey];
    let parsedValue;

    if (changeKey.includes('Inch')) {
      parsedValue = convertInchToCm(changeValue);
    } else if (changeKey.includes('Cm')) {
      parsedValue = convertCmToInch(changeValue);
    } else if (changeKey.includes('Lbs')) {
      parsedValue = convertPoundsToKg(changeValue);
    } else if (changeKey.includes('Kg')) {
      parsedValue = convertKgToPounds(changeValue);
    }

    if (changeKey === 'LengthInch') {
      scanIdForm.setFieldValue('LengthCm', parsedValue);
    } else if (changeKey === 'WidthInch') {
      scanIdForm.setFieldValue('WidthCm', parsedValue);
    } else if (changeKey === 'HeightInch') {
      scanIdForm.setFieldValue('HeightCm', parsedValue);
    } else if (changeKey === 'LengthCm') {
      scanIdForm.setFieldValue('LengthInch', parsedValue);
    } else if (changeKey === 'WidthCm') {
      scanIdForm.setFieldValue('WidthInch', parsedValue);
    } else if (changeKey === 'HeightCm') {
      scanIdForm.setFieldValue('HeightInch', parsedValue);
    } else if (changeKey === 'WeightKg') {
      scanIdForm.setFieldValue('WeightLbs', parsedValue);
    } else if (changeKey === 'WeightLbs') {
      scanIdForm.setFieldValue('WeightKg', parsedValue);
    }

    if (['LengthInch', 'WidthInch', 'HeightInch', 'LengthCm', 'WidthCm', 'HeightCm'].includes(changeKey)) {
      scanIdForm.setFieldValue('VolumeCft', getVolumeCft(allValues.LengthInch, allValues.WidthInch, allValues.HeightInch));
      scanIdForm.setFieldValue('VolumeCbm', getVolumeCbm(allValues.LengthCm, allValues.WidthCm, allValues.HeightCm));
    }
  }, []);

  const handleSubmit = useCallback(() => {
    scanIdForm.submit();
  }, []);

  const handleSuccess = useCallback(() => {
    refetch();
    handleCancel();
  }, [record?.Id]);

  const handleSuccessDelete = useCallback(() => {
    refetch();
  }, []);

  const handleFinishScanId = useCallback((values) => {
    const newData = { ...values };
    newData.WarehouseIn_JobNumber = record?.JobNumber;
    newData.StorageLocation_Fk = get(values, 'StorageLocation_Fk.value', values.StorageLocation_Fk || null);
    newData.Warehouse_Fk = get(values, 'Warehouse_Fk.value', values.Warehouse_Fk || null);
    newData.UnitOfMeasure_Fk = get(values, 'UnitOfMeasure_Fk.value', values.UnitOfMeasure_Fk || null);
    setFormData(newData);
  }, [record?.Id]);

  const handleSuccessDistributeQuantity = useCallback(() => {
    refetch();
    onSuccessDistributeQuantity();
  }, []);

  const handleSuccesConfirmUnconfirmGoods = useCallback(() => {
    handleSuccess();
    onSuccessConfirmUnconfirmGoods();
  }, []);

  const handleConfirmGoods = useCallback((selectedRowKeys) => {
    setFormDataConfirmGoods({ ScanId_POPartLocation_Fks: selectedRowKeys });
  }, []);

  const handleUnconfirmGoods = useCallback((selectedRowKeys) => {
    setFormDataUnConfirmGoods({ ScanId_POPartLocation_Fks: selectedRowKeys });
  }, []);

  const handlePutawayGoods = useCallback((selectedRowKeys) => {
    setFormDataPutawayGoods({ ScanId_POPartLocation_Fks: selectedRowKeys });
  }, []);

  const handleUnconfirmPutawayGoods = useCallback((selectedRowKeys) => {
    setFormDataUnConfirmPutawayGoods({ ScanId_POPartLocation_Fks: selectedRowKeys });
  }, []);

  const handleCancelScanId = useCallback((id) => {
    setFormDataDelete({ id });
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0,
    }),
  };

  useEffect(() => {
    if (currentTab === 'receivedGoods') {
      const disableUnconfirmGoodsAction = selectedRowKeys.find((rowId) => {
        const checkIfnotPutAwayScanId = list.find((scanId) => scanId.Id === rowId && Boolean(scanId.PutawayDate));
        if (checkIfnotPutAwayScanId) return true;
        return false;
      });

      onSetModalActions([
        <Space key={1}>&nbsp;</Space>,
        <Space key={2}>
          <Button type="default" loading={loadingConfirmGoods} disabled={loadingConfirmGoods} onClick={() => handleConfirmGoods(selectedRowKeys)}>
            Confirm Goods
          </Button>
          <Button type="default" loading={loadingUnConfirmGoods} disabled={loadingUnConfirmGoods || Boolean(disableUnconfirmGoodsAction)} onClick={() => handleUnconfirmGoods(selectedRowKeys)}>
            Unconfirm Goods
          </Button>
          <Button type="default" loading={loadingPutawayGoods} disabled={loadingPutawayGoods} onClick={() => handlePutawayGoods(selectedRowKeys)}>
            Confirm Putaway
          </Button>
          <Button type="default" loading={loadingUnconfirmPutawayGoods} disabled={loadingUnconfirmPutawayGoods} onClick={() => handleUnconfirmPutawayGoods(selectedRowKeys)}>
            Unconfirm Putaway
          </Button>
          <div style={{ width: 24 }}>&nbsp;</div>
          <ManualDistribution scanIdList={list} poLineItemList={poLineItems} onSuccess={handleSuccessDistributeQuantity} />
          <AutoDistribution />
        </Space>,
      ]);
    }
  }, [currentTab, poLineItems, list, loadingConfirmGoods, selectedRowKeys]);

  const scanIdColumns = [
    {
      title: 'Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (_, { ConfirmedDate, PutawayDate }) => (
        <span style={{ userSelect: 'text' }}>
          {parseScanIdStatus(ConfirmedDate, PutawayDate)}
        </span>
      ),
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Warehouse',
      dataIndex: 'Warehouse',
      key: 'Warehouse',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
    {
      title: 'Storage Location',
      dataIndex: 'StorageLocation',
      key: 'StorageLocation',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code}
        </span>
      ),
    },
    {
      title: 'Label Series',
      dataIndex: 'LabelSeries',
      key: 'LabelSeries',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${text || ''} of ${list.length}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (INCH) L x W x H',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${record?.LengthInch || 0} x ${record?.WidthInch || 0} x ${record?.HeightInch || 0}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (CM) L x W x H',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${record?.LengthCm || 0} x ${record?.WidthCm || 0} x ${record?.HeightCm || 0}`}
        </span>
      ),
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
    },
    {
      title: 'Volume (CFT)',
      dataIndex: 'VolumeCft',
      key: 'VolumeCft',
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 140,
      render: (_, record) => (
        <Space size={16}>
          <a onClick={() => handleEdit(record?.Id)}>
            <EditFilled />
            {' '}
            Edit
          </a>
          <Popconfirm
            title="Delete Scan ID"
            description="Are you sure you want to delete this scan id?"
            onConfirm={() => handleCancelScanId(record?.Id)}
            okText="Yes"
            cancelText="No"
          >
            <a style={{ color: 'red' }}>
              <DeleteFilled />
              {' '}
              Cancel
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const lineItemColumns = [
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Number}
        </span>
      ),
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Description}
        </span>
      ),
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const expandedRowRender = ({ ScanId, AttachedPOLineItemToScanId }) => (
    <>
      {AttachedPOLineItemToScanId.length > 0 ? (
        <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
          <TableSubSmall
            size="small"
            dataSource={AttachedPOLineItemToScanId}
            columns={lineItemColumns}
            pagination={false}
            rowKey={(record) => `${ScanId}-${record?.Id}`}
            scroll={{
              x: true,
            }}
          />
        </div>
      ) : (<div>No Items</div>)}
    </>
  );

  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={16}>
        <Col xs={24}>
          <CardStyled>
            <Flex justify="space-between" align="center">
              <Typography.Title level={5}>Scan ID Details</Typography.Title>
              <InboundJobAddScanId
                record={scanIdDetail}
                form={scanIdForm}
                onFinish={handleFinishScanId}
                onValuesChange={handleValuesChange}
                onSave={handleSubmit}
                onOpen={handleOpen}
                onCancel={handleCancel}
                loading={loading}
                detailLoading={detailLoading}
                open={open}
              />
            </Flex>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={list}
              loading={listLoading}
              columns={scanIdColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
              rowKey="Id"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys,
                ...rowSelection,
              }}
              expandable={{
                rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                defaultExpandAllRows: true,
                expandedRowRender,
              }}
            />
          </CardStyled>
          <Divider />
          <CardStyled>
            <Typography.Title level={5}>Available PO Line Items to receive</Typography.Title>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              loading={poLineItemsLoading}
              dataSource={poLineItems}
              columns={poLineItemColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
            />
          </CardStyled>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(InboundJobReceivedGoods);
