import { memo, useEffect } from 'react';
import {
  Col, DatePicker, Divider, Form, Input, InputNumber, Row,
} from 'antd';

import { FormStyled } from '@components/Styles/FormStyles';
import SelectUnitOfMeasure from '@components/Select/SelectUnitOfMeasure';
import { timestampToDate } from '@utils/helpers/date';

function OverpackForm({
  form,
  onFinish,
  scanIdDetails,
  onValuesChange,
}) {
  useEffect(() => {
    if (scanIdDetails?.Id) {
      form.setFieldsValue({
        ScanId: scanIdDetails.ScanId,
        PackedDate: timestampToDate(scanIdDetails.PackedDate, true, true),
        HeightInch: scanIdDetails.HeightInch,
        WidthInch: scanIdDetails.WidthInch,
        LengthInch: scanIdDetails.LengthInch,
        HeightCm: scanIdDetails.HeightCm,
        WidthCm: scanIdDetails.WidthCm,
        LengthCm: scanIdDetails.LengthCm,
        WeightKg: scanIdDetails.WeightKg,
        WeightLbs: scanIdDetails.LengthCm,
        VolumeCbm: scanIdDetails.VolumeCbm,
        VolumeCft: scanIdDetails.VolumeCft,
        UnitOfMeasure_Fk: scanIdDetails.UnitOfMeasure_Fk ? {
          label: `[${scanIdDetails.UnitOfMeasure.Code}] ${scanIdDetails.UnitOfMeasure.Description}`,
          value: scanIdDetails.UnitOfMeasure.Id,
        } : null,
      });
    }
  }, [scanIdDetails]);

  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Row gutter={24}>
        <Col sm={8}>
          <Form.Item
            name="ScanId"
            label="Overpack ID"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item
            name="UnitOfMeasure_Fk"
            label="UOM"
          >
            <SelectUnitOfMeasure />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item
            name="PackedDate"
            label="Packed Date"
          >
            <DatePicker disabled placeholder="" />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col sm={12}>
          <Form.Item
            label="Length (Inch)"
            name="LengthInch"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="Width (Inch)"
            name="WidthInch"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="Height (Inch)"
            name="HeightInch"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="Weight (LBS)"
            name="WeightLbs"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="CFT"
            name="VolumeCft"
          >
            <InputNumber
              precision={3}
            />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Length (Cm)"
            name="LengthCm"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="Width (Cm)"
            name="WidthCm"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="Height (Cm)"
            name="HeightCm"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="Weight (KG)"
            name="WeightKg"
          >
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item
            label="CBM"
            name="VolumeCbm"
          >
            <InputNumber
              precision={3}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormStyled>
  );
}

export default memo(OverpackForm);
