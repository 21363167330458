import { gql } from '@apollo/client';

const GET_SCAN_ID_BY_ID = gql`
    query GetScanIdById($Id: Int) {
        getScanIdById(Id: $Id) {
            code
            success
            message
            result {
                Id
                ScanId
                WarehouseIn_JobNumber
                WarehouseOut_JobNumber
                OverpackId_PoPartLocation_Fk
                LabelSeries
                LotNumber
                UnitOfMeasure_Fk
                TotalQuantity
                LengthInch
                WidthInch
                HeightInch
                LengthCm
                WidthCm
                HeightCm
                WeightLbs
                WeightKg
                VolumeCbm
                VolumeCft
                Warehouse_Fk
                StorageLocation_Fk
                Is_Overpack
                ConfirmedBy
                ConfirmedDate
                PutawayBy
                PutawayDate
                PackedBy
                PackedDate
                CreatedBy
                CreatedDate
                StorageLocation {
                    Id
                    Code
                }
                Warehouse {
                    Id
                    Code
                    Name
                }
                UnitOfMeasure {
                    Id
                    Code
                    Description
                    Remarks
                }
            }
        }
    }
`;

export default GET_SCAN_ID_BY_ID;
