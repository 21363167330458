import React, { memo, useCallback } from 'react';
import {
  Page, View, Document, StyleSheet,
  Text,
  Image,
} from '@react-pdf/renderer';
import {
  TD, TH, TR, Table,
} from '@ag-media/react-pdf-table';

import useLogo from '@hooks/useLogo';

import '@components/Font/Roboto';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { parseAndToFixed } from '@utils/helpers/helpers';
import getDefaultAddress from '@config/defaultAddress';
import getDefaultLogo from '@config/defaultLogo';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontSize: 8,
    padding: 12,
    lineHeight: '1.4px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    height: 60,
    marginTop: -12,
  },
  subHeaderText: {
    backgroundColor: '#ddd',
    padding: '4px',
    textAlign: 'center',
  },
  subHeaderTextInner: {
    fontWeight: 600,
  },
  pageNumber: {
    borderTop: '1px solid #ccc',
    position: 'absolute',
    fontSize: 8,
    bottom: 4,
    left: 0,
    right: 0,
    textAlign: 'center',
    margin: '0 12px',
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pageNumberPrint: {
    display: 'flex',
    flexDirection: 'row',
  },
  marginRight: {
    marginRight: 16,
  },
  tableNoBorder: {
    border: 'none',
  },
  tableHeader: {
    border: '1px solid #ccc',
  },
  fontBold: {
    fontWeight: 600,
  },
  textCenter: {
    textAlign: 'center',
  },
  headerSection: {
    paddingTop: 2,
    textAlign: 'center',
    backgroundColor: '#ddd',
  },
  headerSectionText: {
    fontWeight: 600,
    fontSize: 10,
  },
  cellLabel: {
    justifyContent: 'space-between',
  },
});

function PrintFinalGoods({
  data,
  useParentLogo,
  useCustomerLogo,
  user,
  onLoadPrintFile,
}) {
  let logo = null;
  const defaultLogo = getDefaultLogo();

  if (useCustomerLogo) {
    logo = useLogo({ logoUrl: get(data, 'Customer.Logo', null) });
  } else if (useParentLogo) {
    logo = useLogo({ logoUrl: get(data, 'CustomerParent.Logo', null) });
  } else if (!useCustomerLogo && !useParentLogo) {
    logo = useLogo({ logoUrl: defaultLogo });
  }

  const handleRender = useCallback((blob) => {
    onLoadPrintFile(blob);
  }, []);

  if (!data?.Id) return null;

  const customerHeader = getDefaultAddress();

  return (
    <Document onRender={handleRender}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.fontBold}>{customerHeader.name}</Text>
            <Text>{customerHeader.address}</Text>
          </View>
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '200px' } }}
            tdStyle={{ alignItems: 'start', padding: 2 }}
            weightings={[0.25, 0.75]}
          >
            <TR>
              <TD style={styles.cellLabel}>
                <Text style={styles.fontBold}>Telephone</Text>
              </TD>
              <TD>
                <Text>{` ${customerHeader.telephone || ''}`}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={styles.cellLabel}>
                <Text style={styles.fontBold}>Fax</Text>
              </TD>
              <TD>
                <Text>{`${customerHeader.fax || ''}`}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={styles.cellLabel}>
                <Text style={styles.fontBold}>Email</Text>
              </TD>
              <TD>
                <Text>{`${customerHeader.email || ''}`}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={styles.cellLabel}>
                <Text style={styles.fontBold}>Web</Text>
              </TD>
              <TD>
                <Text>{`${customerHeader.web || ''}`}</Text>
              </TD>
            </TR>
          </Table>
          <View>
            {logo && (
              <Image
                style={styles.logo}
                src={logo}
              />
            )}
          </View>
        </View>
        <View style={{ height: 4 }} />
        <View style={styles.headerSection}>
          <Text style={styles.headerSectionText}>GRN / MR /WR REPORT</Text>
        </View>
        <View style={{ height: 4 }} />
        <Table
          style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
          tdStyle={{ alignItems: 'start', padding: 2 }}
          weightings={[0.15, 0.35, 0.15, 0.35]}
        >
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>GRN /MR / WR No.</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'JobNumber', '')}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Customer</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`[${get(data, 'Customer.Code', '')}] ${get(data, 'Customer.Name', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>P.O. Type</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'PurchaseOrder.POType', '')}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Customer Ref.</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'CustomerReference', '')}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Arrival Date</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'ArrivalDate', '')}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Supplier</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`[${get(data, 'Supplier.Code', '')}] ${get(data, 'Supplier.Name', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>No. Of Packages</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'PackageCount', '')}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Supplier Ref.</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'SupplierReference', '')}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>No. Of Scan ID</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{data?.AttachedScanIds ? data?.AttachedScanIds.length : '0'}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Shipper</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`[${get(data, 'Shipper.Code', '')}] ${get(data, 'Shipper.Name', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Carrier/Trucker</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`[${get(data, 'Carrier.Code', '')}] ${get(data, 'Carrier.Name', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Shipper Ref.</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'ShipperReference', '')}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Carrier Ref.</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'CarrierReference', '')}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Consignee</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`[${get(data, 'Consignee.Code', '')}] ${get(data, 'Consignee.Name', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD />
            <TD />
            <TD style={styles.cellLabel}>
              <Text style={styles.fontBold}>Consignee Ref.</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'ConsigneeReference', '')}</Text>
            </TD>
          </TR>
        </Table>

        {(data?.AttachedScanIds || []).map((scanIdData, index) => (
          <View style={styles.details} key={scanIdData.Id}>
            <View wrap={false}>
              <Table
                style={{ ...styles.tableHeader, ...{ width: '100%', backgroundColor: '#f1f1f1' } }}
                tdStyle={{
                  alignItems: 'start', padding: 4, border: 'none', fontSize: 11,
                }}
                weightings={[0.15, 0.35, 0.15, 0.35]}
              >
                <TR>
                  <TD><Text style={styles.fontBold}>Warehouse</Text></TD>
                  <TD>
                    <Text>
                      {get(scanIdData, 'Warehouse.Code', null) ? `[${get(scanIdData, 'Warehouse.Code', '')}] ${get(scanIdData, 'Warehouse.Name', '')}` : ''}
                    </Text>
                  </TD>
                  <TD><Text style={styles.fontBold}>Location</Text></TD>
                  <TD>
                    <Text>
                      {get(scanIdData, 'StorageLocation.Code', '')}
                    </Text>
                  </TD>
                </TR>
              </Table>
              <View style={{ height: 8 }} />
              <Table
                style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                tdStyle={{ alignItems: 'start', padding: 2 }}
                weightings={[0.15, 0.25, 0.1, 0.2, 0.1, 0.2]}
              >
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Scan ID</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{get(scanIdData, 'ScanId', '')}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>UOM</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{get(scanIdData, 'UnitOfMeasure.Description', '')}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Weight KGS</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'WeightKg', ''))}</Text>
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Label Series</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{`${index + 1}/${data?.AttachedScanIds.length}`}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>CBM</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'VolumeCbm', ''), 3)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Weight LBS</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'WeightLbs', ''))}</Text>
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Dims. CM (LxWxH)</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{`${parseAndToFixed(scanIdData?.LengthCm) || 0} x ${parseAndToFixed(scanIdData?.WidthCm) || 0} x ${parseAndToFixed(scanIdData?.HeightCm) || 0}`}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>CFT</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'VolumeCft', ''), 3)}</Text>
                  </TD>
                  <TD />
                  <TD />
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Dims. INCH (LxWxH)</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{`${parseAndToFixed(scanIdData?.LengthInch) || 0} x ${parseAndToFixed(scanIdData?.WidthInch) || 0} x ${parseAndToFixed(scanIdData?.HeightInch) || 0}`}</Text>
                  </TD>
                  <TD />
                  <TD />
                  <TD />
                  <TD />
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Goods Received</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{scanIdData?.ConfirmedDate && 'Confirmed'}</Text>
                  </TD>
                  <TD />
                  <TD />
                  <TD />
                  <TD />
                </TR>
              </Table>
            </View>

            {(scanIdData?.AttachedPOLineItemToScanId || []).map((lineItem) => (
              <View wrap={false} key={lineItem?.LineNumber}>
                <Table
                  style={{ marginTop: 6, border: '1px solid #ccc' }}
                  tdStyle={{
                    alignItems: 'start', padding: '2px', lineHeight: '1px', border: '1px solid #ccc',
                  }}
                  weightings={[0.2, 0.5, 0.1, 0.1, 0.1]}
                >
                  <TH style={{ backgroundColor: '#e1e1e1' }}>
                    <TD>P.O Number</TD>
                    <TD>Part</TD>
                    <TD>Quantity</TD>
                    <TD>UOM</TD>
                    <TD>Line No.</TD>
                  </TH>
                  <TR>
                    <TD><Text>{get(lineItem, 'PurchaseOrder.PONumber', '')}</Text></TD>
                    <TD><Text>{get(lineItem, 'Part.Part_Description', '')}</Text></TD>
                    <TD><Text>{get(lineItem, 'AssignedQuantity', '')}</Text></TD>
                    <TD><Text>{get(lineItem, 'UnitOfMeasure.Description', '')}</Text></TD>
                    <TD><Text>{get(lineItem, 'LineNumber', '')}</Text></TD>
                  </TR>
                  <TR>
                    <TD weighting={1} style={styles.details}>
                      <Table
                        style={{ ...styles.tableNoBorder, ...{ width: '100%', marginBottom: 4 } }}
                        tdStyle={{ alignItems: 'start', padding: 2 }}
                        weightings={[0.2, 0.2, 0.1, 0.15, 0.15, 0.2]}
                      >
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Schedule B/HS Code</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'Part.Schedule_B', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>P.O. Status</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'PurchaseOrder.POStatus', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Mode of Transport</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'ModeOfTransport.Description', '')}</Text>
                          </TD>
                        </TR>
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Country Origin</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'CountryOfOrigin.Name', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Line Status</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'LineStatus', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>ECCN</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'Part.ECCN', '')}</Text>
                          </TD>
                        </TR>
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>P.O. Remarks</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'PurchaseOrder.Remarks', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Hazardous</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'Part.Is_Hazardous', 0) ? 'Yes' : 'No'}</Text>
                          </TD>
                          <TD />
                          <TD />
                        </TR>
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Additional Desc</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItem, 'AdditionalDescription', '')}</Text>
                          </TD>
                          <TD />
                          <TD />
                          <TD />
                          <TD />
                        </TR>
                      </Table>
                    </TD>
                  </TR>
                  <TH style={{ backgroundColor: '#e1e1e1' }}>
                    <TD weighting={0.2}>
                      Part Details:
                    </TD>
                    <TD weighting={0.8} />
                  </TH>
                  <TR>
                    <TD weighting={1} style={styles.details}>
                      <Table
                        style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                        tdStyle={{ alignItems: 'start', padding: 2 }}
                        weightings={[0.2, 0.2, 0.1, 0.15, 0.15, 0.2]}
                      >
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Currency</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{`[${get(lineItem, 'Currency.Code', '')}] ${get(lineItem, 'Currency.Description', '')} `}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Unit Price</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{parseAndToFixed(get(lineItem, 'UnitPrice', ''))}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Total Price</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{parseAndToFixed(get(lineItem, 'TotalPrice', ''))}</Text>
                          </TD>
                        </TR>
                      </Table>
                    </TD>
                  </TR>
                </Table>
              </View>
            ))}
            <View style={{ height: 8 }} />
          </View>
        ))}

        <View style={styles.pageNumber} fixed>
          <View style={styles.pageNumberPrint}>
            <Text style={styles.marginRight}>
              Printed On:
              {' '}
              {dayjs().format('DD MMM YYYY')}
            </Text>
            <Text style={styles.marginRight}>
              {' '}
            </Text>
            <Text style={styles.marginRight}>
              Printed By:
              {' '}
              {user?.Full_Name}
            </Text>
          </View>
          <Text
            render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )}
            fixed
          />
        </View>
      </Page>
    </Document>
  );
}

export default memo(PrintFinalGoods);
